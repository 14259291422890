<template>
  <v-row justify="center">
    <v-dialog v-model="show" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-5">Update Password</span>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                label="Password Lama"
                v-model="form.password"
                :rules="rules.password"
                :append-icon="iconEyeOld ? 'mdi-eye' : 'mdi-eye-off'"
                :type="iconEyeOld ? 'text' : 'password'"
                @click:append="iconEyeOld = !iconEyeOld"
              ></v-text-field>
              <v-text-field
                label="Password Baru"
                v-model="form.newPassword"
                :rules="rules.newPassword"
                :append-icon="iconEyeNew ? 'mdi-eye' : 'mdi-eye-off'"
                :type="iconEyeNew ? 'text' : 'password'"
                @click:append="iconEyeNew = !iconEyeNew"
              ></v-text-field>
              <v-text-field
                label="Ulangi Password Baru"
                v-model="confrim_password"
                :rules="rules.confrim_password"
                :append-icon="iconEyeNew ? 'mdi-eye' : 'mdi-eye-off'"
                :type="iconEyeNew ? 'text' : 'password'"
                @click:append="iconEyeNew = !iconEyeNew"
              ></v-text-field>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="update"
                  >Perbaharui</v-btn
                >
                <v-btn color="blue darken-1" text @click="closeShow"
                  >Batal</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card-title>
      </v-card>
    </v-dialog>
    <SnackBar :status="statusSnack" :text="message_snack" @closeSnackBar="closeSnack($event)"/>
  </v-row>
</template>

<script>
import { user } from "../api";
import SnackBar from "./SnackBar";
export default {
  name: "UpdatePassword",
  components: { SnackBar },
  props: {
    show: Boolean,
  },
  data() {
    return {
      statusSnack: false,
      message_snack: null,
      valid: true,
      iconEyeOld: false,
      iconEyeNew: false,
      rules: {
        password: [(v) => !!v || "Password tidak boleh kosong"],
        newPassword: [
          (v) => !!v || "Password tidak boleh kosong",
          (v) => (v && v.length >= 8) || "Password terlalu pendek.",
        ],
        confrim_password: [
          (v) => !!v || "Tidak boleh kosong",
          (v) =>
            v === this.form.newPassword || "Harus sama dengan password baru!",
        ],
      },
      form: {
        password: null,
        newPassword: null,
      },
      confrim_password: null,
    };
  },
  methods: {
    update() {
      if (this.$refs.form.validate()) {
        this.updatePassword();
      }
    },
    closeShow() {
      this.form = {
        password: null,
        newPassword: null,
      };
      this.confrim_password = null;
      this.$refs.form.resetValidation();
      this.$emit("updateStatus", false);
    },
    async updatePassword() {
      try {
        let res = await user.updatePassword(this.form);
        if (res.status === 200) {
          this.closeShow();
        }
      } catch (e) {
        if (e.response.status === 403) {
          this.message_snack = "Password salah";
          if (this.statusSnack) this.statusSnack = false;
          this.statusSnack = true;
        }
      }
    },
    closeSnack(value){
      this.statusSnack = value
    }
  },
};
</script>
