<template>
  <v-row>
      <v-col sm="12" md="12">
        <v-card :loading="status_button">
          <v-card-title>
            <span class="text-5">Laporan Neraca</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col sm="12" md="3">
                <v-select
                  label="Bulan"
                  v-model="form.index"
                  :items="items"
                ></v-select>
              </v-col>
              <v-col sm="12" md="1">
                <v-btn color="primary" :disabled="status_button" @click="load"
                  ><v-icon>mdi-book-search</v-icon> &nbsp;Proses</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
                <v-col sm="12" md="12" v-if="data">
                  <h3 class="b">Harta</h3>
                   <v-simple-table>
                       <template v-slot:default>
                           <thead>
                               <th class="text-left">
                                   Akun
                               </th>
                               <th class="text-left">
                                   Nama
                               </th>
                               <th class="text-right">
                                   Saldo
                               </th>
                           </thead>
                           <tbody>
                               <tr v-for="(d, index) in data.harta" :key="index">
                                   <td class="text-left">
                                       {{d.akun}}
                                   </td>
                                   <td>
                                       {{d.nama}}
                                   </td>
                                   <td class="text-right">
                                       <b>{{convertToRupian(d.saldo)}}</b>
                                   </td>
                               </tr>
                               <tr>
                                   <td colspan="2" class="text-right"><b>Total Harta</b></td>
                                   <td class="text-right"><b>{{convertToRupian(data.total_harta)}}</b></td>
                               </tr>
                           </tbody>
                       </template>
                   </v-simple-table>
                   <h3 class="b">Kewajiban</h3>
                   <v-simple-table>
                       <template v-slot:default>
                           <thead>
                               <th class="text-left">
                                   Akun
                               </th>
                               <th class="text-left">
                                   Nama
                               </th>
                               <th class="text-right">
                                   Saldo
                               </th>
                           </thead>
                           <tbody>
                               <tr v-for="(d, index) in data.kewajiban" :key="index">
                                   <td class="text-left">
                                       {{d.akun}}
                                   </td>
                                   <td>
                                       {{d.nama}}
                                   </td>
                                   <td class="text-right">
                                       <b>{{convertToRupian(d.saldo)}}</b>
                                   </td>
                               </tr>
                               <tr>
                                   <td colspan="2" class="text-right"><b>Total Kewajiban</b></td>
                                   <td class="text-right"><b>{{convertToRupian(data.total_kewajiban)}}</b></td>
                               </tr>
                               
                           </tbody>
                       </template>
                   </v-simple-table>
                   <h3 class="b">Modal</h3>
                   <v-simple-table>
                       <template v-slot:default>
                           <thead>
                               <th class="text-left">
                                   Akun
                               </th>
                               <th class="text-left">
                                   Nama
                               </th>
                               <th class="text-right">
                                   Saldo
                               </th>
                           </thead>
                           <tbody>
                               <tr v-for="(d, index) in data.modal" :key="index">
                                   <td class="text-left">
                                       {{d.akun}}
                                   </td>
                                   <td>
                                       {{d.nama}}
                                   </td>
                                   <td class="text-right">
                                       <b>{{convertToRupian(d.saldo)}}</b>
                                   </td>
                               </tr>
                               <tr>
                                   <td colspan="2" class="text-right"><b>Total Modal</b></td>
                                   <td class="text-right"><b>{{convertToRupian(data.total_modal)}}</b></td>
                               </tr>
                               <tr>
                                   <td colspan="2" class="text-right"><b>Laba</b></td>
                                   <td class="text-right"><b>{{convertToRupian(data.laba)}}</b></td>
                               </tr>
                               <tr>
                                   <td colspan="2" class="text-right"><b>Modal + Kewajiban + Laba</b></td>
                                   <td class="text-right"><b>{{convertToRupian(data.total_pasiva)}}</b></td>
                               </tr>
                               <tr>
                                   <td class="text-right"><b>Balance: Harta | Modal + Kewajiban + laba</b></td>
                                   <td class="text-right"><b>{{convertToRupian(data.total_harta)}}</b></td>
                                   <td class="text-right"><b>{{convertToRupian(data.total_pasiva)}}</b></td>
                               </tr>
                               
                           </tbody>
                       </template>
                   </v-simple-table>
                </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
  </v-row>
</template>
<script>
import { month, laporan, formatRupiah } from "../api";
export default {
  name: "LaporanNeraca",
  data() {
    return {
      status_button: false,
      items: month,
      form: {
        index: null,
      },
      data: null,
    };
  },
  methods: {
    load() {
      this.getLaporan();
    },
    convertToRupian(value){
        if(value<0){
            return `(${formatRupiah(value, '')})`
        }else{
            return formatRupiah(value, '')
        }
    },
    async getLaporan() {
      if (this.form.index !== null) {
        try {
          this.status_button = true;
          let res = await laporan.getNeraca(this.form);
          if (res.status === 200) {
            this.data = res.data;
          }
          this.status_button = false;
        } catch (e) {
          this.status_button = false;
          console.log(e);
        }
      } else {
        this.data = null;
      }
    },
  },
};
</script>
<style scoped>
.b{
    margin-bottom: 25px;
}
</style>