<template>
    <v-snackbar
      v-model="status"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="close"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
</template>
<script>

export default {
    name:'SnackBar',
    props:{
        status:Boolean,
        text:String
    },
    data(){
        return{
            
        }
    },
    methods:{
        close(){
            this.$emit('closeSnackBar', false)
        }
    }
}
</script>
