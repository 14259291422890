import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

Vue.use(Vuex)

const vuexLocalStorage = new VuexPersist({
    key:'store',
    storage:window.localStorage
});

export default new Vuex.Store({
    plugins: [vuexLocalStorage.plugin],
    state:{
        user:null
    },
    getters:{
        getUser: state => state.user
    },
    mutations:{
        addUser: (state, data)=>{
            state.user = data
        },
        deleteUser: (state)=>{
            state.user = null
        }
    },

    actions:{
        setUser:(contex, data)=>{
            contex.commit('addUser', data)
        },
        clearUser:(contex)=>{
            contex.commit('deleteUser')
        }
    }

})