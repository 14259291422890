exports.month = [
    { text: 'JULI', value: 1 },
    { text: 'AGUSTUS', value: 2 },
    { text: 'SEPTEMBER', value: 3 },
    { text: 'OKTOBER', value: 4 },
    { text: 'NOVEMBER', value: 5 },
    { text: 'DESEMBER', value: 6 },
    { text: 'JANUARI', value: 7 },
    { text: 'FEBRUARI', value: 8 },
    { text: 'MARET', value: 9 },
    { text: 'APRIL', value: 10 },
    { text: 'MEI', value: 11 },
    { text: 'JUNI', value: 12 },
]

