<template>
  <v-row>
    <v-col sm="12" md="12">
      <v-row>
        <v-col md="6" sm="12" offset-md="3">
          <v-card elevation="2" outlined :loading="loading">
            <v-card-title> Register Sub Kelompok </v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-autocomplete
                  v-model="form.jenis"
                  label="Kelompok"
                  :items="akun"
                ></v-autocomplete>
                <v-text-field
                  v-model="form.nama"
                  label="Nama"
                  required
                  :rules="namaRules"
                >
                </v-text-field>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  class="ma-2"
                  @click="save"
                  :disabled="loading"
                  ><v-icon dark left>mdi-checkbox-marked-circle</v-icon>
                  Simpan</v-btn
                >
                <v-btn
                  color="red"
                  class="ma-2"
                  @click="reset"
                  dark
                  :disabled="loading"
                  ><v-icon left>mdi-cancel</v-icon> Batal</v-btn
                >
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-col md="12" sm="12">
          <v-data-table
            :headers="headers"
            :items="data_kelompok"
          ></v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { akun } from "../api";
export default {
  name: "SubKelompok",
  data() {
    return {
      loading: false,
      valid: true,
      namaRules: [
        (v) => !!v || "Nama Kelompok Tidak boleh kosong!!!",
        (v) => (v && v.length >= 3) || "Nama kelompok terlalu pendek",
      ],
      form: {
        jenis: null,
        nama: null,
      },
      akun: [],
      headers: [
        { text: "Akun", value: "akun" },
        { text: "Kelompok", value: "kelompok" },
        { text: "Nama", value: "nama" },
      ],
      data_kelompok: [],
    };
  },
  methods: {
    getData() {
      this.getSubkelompok();
      this.getKelompokList();
    },
    save() {
      if (this.$refs.form.validate()) {
        this.saveSubKelompok();
      }
    },
    reset() {
      this.form = {
        jenis: null,
        nama: null,
      };
      this.$refs.form.resetValidation();
    },
    async saveSubKelompok() {
      try {
        this.loading = true;
        let res = await akun.saveJenis(this.form);
        if (res.status === 200) {
          this.getSubkelompok();
          this.loading = false;
          this.reset();
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getSubkelompok() {
      try {
        let res = await akun.getJenis();
        if (res.status === 200) {
          this.data_kelompok = res.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getKelompokList() {
      try {
        let res = await akun.getKelompokList();
        if (res.status === 200) {
          this.akun = res.data;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  created() {
    this.getSubkelompok();
    this.getKelompokList();
  },
};
</script>
