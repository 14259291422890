<template>
  <v-row justify="center">
    <v-dialog v-model="show" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-5">Tambah List Pelaksana</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-select
              :items="penerima_list"
              :rules="rulesPenerima"
              v-model="form.lembaga"
              label="Lembaga"
            ></v-select>
            <v-text-field
              label="Nama"
              :rules="rulesNama"
              v-model="form.nama"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="simpan"> Simpan </v-btn>
          <v-btn color="blue darken-1" text @click="batal"> Batal </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { jurnal } from "../api";
export default {
  name: "Penerima",
  props: {
    show: Boolean,
  },
  data() {
    return {
      valid: true,
      rulesPenerima: [(v) => !!v || "Tidak boleh kosong"],
      rulesNama: [
        (v) => !!v || "Nama Kelompok Tidak boleh kosong!!!",
        (v) => (v && v.length >= 3) || "Terlalu pendek.",
      ],
      penerima_list: [
        { value: "SD IT Putra", text: "SD IT Putra" },
        { value: "SD IT Putri", text: "SD IT Putri" },
        { value: "SMP IT Putra", text: "SMP IT Putra" },
        { value: "SMP IT Putri", text: "SMP IT Putri" },
        { value: "SMP IT Putra Fullday", text: "SMP IT Putra Fullday" },
        { value: "Diniyyah", text: "Diniyyah" },
        { value: "Ma Plus", text: "Ma Plus" },
        { value: "SMA IT Putri", text: "SMA IT Putri" },
        { value: "SMA IT Putra Fullday", text: "SMA IT Putra Fullday" },
        { value: "Idad Mualimat", text: "Idad Mualimat" },
        { value: "SD IT Sembalun", text: "SD IT Sembalun" },
        { value: "Paud", text: "Paud" },
        { value: "PLS", text: "PLS" },
        { value: "Asrama Putra", text: "Asrama Putra" },
        { value: "Asrama Putri", text: "Asrama Putri" },
        { value: "Kebendaharaan", text: "Kebendaharaan" },
        { value: "Unit Usaha", text: "Unit Usaha" },
        { value: "Perpustakaan", text: "Perpustakaan" },
        { value: "UKS", text: "UKS" },
        { value: "Sarpras", text: "Sarpras" },
        { value: "CS", text: "CS" },
        { value: "Sekretaris", text: "Sekertaris" },
        { value: "Personalia", text: "Personalia" },
        { value: "Tim IT", text: "Tim IT" },
        { value: "Keamanan", text: "Keamanan" },
        { value: "Dept. Bahasa", text: "Dept. Bahasa" },
        { value: "Dept. Tahfizh", text: "Dept. Tahfizh" },
        { value: "Dept. KSA", text: "Dept. KSA" },
        { value: "Litbang", text: "Litbang" },
        { value: "Dewan Majlis", text: "Dewan Majlis" },
        { value: "Komite", text: "Komite" },
        { value: "Dapur", text: "Dapur" },
        { value: "Tim Pengadaan Konsumsi", text: "Tim Pengadaan Konsumsi" },
      ],
      form: {
        nama: null,
        lembaga: null,
      },
    };
  },
  methods: {
    async simpan() {
      if (this.$refs.form.validate()) {
        try {
          let res = await jurnal.savePenerima(this.form);
          if (res.status === 200) {
            this.form = {
              nama: null,
              lembaga: null,
            };
            let res = await jurnal.getPenerima();
            this.$emit("updatePenerima", { status: false, penerima: res.data });
            this.$refs.form.resetValidation();
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    async batal() {
      this.form = {
        nama: null,
        lembaga: null,
      };
      this.$refs.form.resetValidation();
      let res = await jurnal.getPenerima();
      this.$emit("updatePenerima", {
        status: false,
        penerima: res.data,
      });
    },
  },
};
</script>
