import akun from './akun'
import {formatRupiah, dateIndo} from './rupiah'
import jurnal from './jurnal'
import user from './user'
import axios from 'axios'
import router from '../router'
import store from '../store'
import bukuBesar  from './bukubesar'
import {month} from './listBulan'
import laporan from './laporan'

//const url = 'http://localhost:5000'
const url = 'https://api-cakep.ponpesabuhurairah.id'

const axiosApi = axios.create({
    baseURL:url
})

axiosApi.interceptors.request.use((config)=>{
    if(!config.url.includes('/login')){
        config.headers = {Authorization: `Bearer ${store.getters.getUser.token}`}
    }
    return config
})

axiosApi.interceptors.response.use((config)=>{
    return config
},(err)=>{
    if(err.response.status===401){
        store.dispatch('clearUser')
        if(router.currentRoute.path!=='/login'){
            router.push('/login')
        }
    }
    return Promise.reject(err)
})




export {
    jurnal,
    axiosApi,
    akun,
    formatRupiah,
    dateIndo,
    user,
    bukuBesar,
    month,
    laporan
}
