import { axiosApi } from "./index";

let bukuBesar = {
    getBukuBesarAll: ()=>{
        return axiosApi.get('/bukubesar/list')
    },
    getBukuBesarSearch:(key)=>{
        return axiosApi.get(`/bukubesar/search/${key}`)
    },
    bukuBesarAkunAll:(form)=>{
        return axiosApi.post(`/bukubesar/list/akun`, form)
    }
}

export default bukuBesar