<template>
  <v-row>
    <v-col sm="12" md="12">
      <v-tabs>
        <v-tab>Transaksi</v-tab>
        <v-tab @click="getNew">Jurnal</v-tab>
        <v-tab-item class="tab-space"> <Transaksi /> </v-tab-item>
        <v-tab-item class="tab-space">
          <Jurnal ref="JurnalData"/>
        </v-tab-item>
      </v-tabs>
    </v-col>
  </v-row>
</template>
<script>
import Transaksi from "./Transaksi";
import Jurnal from "./Jurnal";

export default {
  name: "JurnalList",
  components: { Transaksi, Jurnal },
  data() {
    return {
      status_tab:false
    };
  },
  methods: {
    getNew(){
      if(this.status_tab){
        this.$refs.JurnalData.getJurnal()
      }else{
        this.status_tab = true
      }

    }
  },
};
</script>
<style scoped>
.tab-space {
  margin-top: 10px;
}
</style>