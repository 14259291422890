<template>
  <v-row>
    <v-col sm="12" md="8" offset-md="2">
      <v-card elevation="2" outlined>
        <v-card-title>Transaksi</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              type="date"
              v-model="form.tgl"
              label="Tanggal"
              :rules="dateRules"
            ></v-text-field>
            <v-autocomplete
              v-model="form.from_akun"
              label="Dari Akun"
              :items="akun"
              :rules="dariAkunRules"
            ></v-autocomplete>
            <div v-for="(item, index) in form.to_akun" :key="index">
              <v-autocomplete
                v-model="item.no_akun"
                label="Ke Akun"
                :items="akun"
                :rules="keAkunRules"
              ></v-autocomplete>
              <v-text-field
                v-model="item.nominal"
                label="Nominal"
                class="centered-input text--darken-3 mt-3"
                type="number"
                autocomplete="off"
                :rules="valueRules"
                :hint="rupiah(item.nominal)"
              ></v-text-field>
            </div>
            <v-btn class="mx-2" @click="addAkun" fab dark small color="success">
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
            <v-btn
              class="mx-2"
              @click="removeAkun"
              fab
              dark
              small
              color="warning"
            >
              <v-icon dark> mdi-minus </v-icon>
            </v-btn>
            <v-text-field
              v-model="form.keterangan"
              label="Keterangan"
              :rules="keteranganRules"
            ></v-text-field>
            <v-autocomplete
              label="Pelaksana"
              v-model="form.penerima"
              :items="penerima_list"
              :rules="penerimaRules"
            ></v-autocomplete>
            <v-btn class="mx-2" @click="addPenerima" fab dark small color="success">
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row align="center" justify="end">
            <v-btn
              color="primary"
              class="ma-2"
              @click="save"
              :disabled="loading"
              ><v-icon dark left>mdi-checkbox-marked-circle</v-icon>
              Simpan</v-btn
            >
            <v-btn
              color="red"
              class="ma-2"
              @click="reset"
              dark
              :disabled="loading"
              ><v-icon left>mdi-cancel</v-icon> Batal</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>

      
    </v-col>
    <InfoDialog
        :isShow="status_dialog"
        :pesan="status_message"
        @updateStatus="dialogStatus($event)"
      />
      <Penerima :show="status_penerima" @updatePenerima="penerimaUpdateStatus($event)"/>
  </v-row>
</template>
<script>
import { akun, formatRupiah, jurnal } from "../api";
import InfoDialog from "./InfoDialog";
import Penerima from './Penerima'
export default {
  name: "Transaksi",
  components: { InfoDialog, Penerima},
  data() {
    return {
      status_dialog: false,
      status_penerima:false,
      status_message: "",
      valid: true,
      akun: [],
      penerima_list: [],
      valueRules: [(v) => !!v || "Tidak boleh kosong"],
      dateRules: [(v) => !!v || "Tidak boleh kosong"],
      dariAkunRules: [(v) => !!v || "Tidak boleh kosong"],
      keAkunRules: [(v) => !!v || "Tidak boleh kosong"],
      keteranganRules: [
        (v) => !!v || "Nama Kelompok Tidak boleh kosong!!!",
        (v) => (v && v.length >= 3) || "Nama kelompok terlalu pendek",
      ],
      penerimaRules: [(v) => !!v || "Tidak boleh kosong"],
      loading: false,
      form: {
        from_akun: null,
        tgl: null,
        keterangan: null,
        penerima: null, //1 for testing
        to_akun: [
          {
            no_akun: null,
            nominal: null,
          },
        ],
      },
    };
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        this.saveJurnal();
      }
    },
    addPenerima(){
      this.status_penerima=false,
      this.status_penerima=true
    },
    penerimaUpdateStatus(value){
      this.status_penerima=value.status
      this.penerima_list = value.penerima
      
    },
    reset() {
      this.form = {
        from_akun: null,
        tgl: null,
        keterangan: null,
        penerima: null, // fort testing
        to_akun: [
          {
            no_akun: null,
            nominal: null,
          },
        ],
      };
      this.$refs.form.resetValidation();
    },
    dialogStatus(value) {
      this.status_dialog = value;
    },
    async saveJurnal() {
      try {
        this.loading = true;
        let res = await jurnal.saveJurnal(this.form);
        if (res.status === 200) {
          this.reset();
          this.loading = false;
          this.status_message = "Data berhasil disimpan!";
          this.status_dialog = true;
        }
      } catch (e) {
        if(e.response.status===477){
          this.reset()
          this.loading=false
          this.status_message = "Transaksi ditolak. Transaksi masadepan pak!!!! "
          this.status_dialog = true

        }
        console.log(e.response);
      }
    },
    addAkun() {
      this.form.to_akun.push({ no_akun: null, nominal: null });
    },
    removeAkun() {
      let widhtn_ = this.form.to_akun.length;
      if (widhtn_ > 1) {
        this.form.to_akun.pop();
      }
    },
    rupiah(value) {
      if (value !== null) {
        return `Rp ${formatRupiah(value, "Rp")}`;
      }
      return "Rp 0";
      //
    },
    async getListAkun() {
      try {
        let res = await akun.getAkunList();
        if (res.status === 200) {
          this.akun = res.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getListPenerima() {
      try {
        let res = await jurnal.getPenerima();
        if (res.status === 200) {
          this.penerima_list = res.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
  },

  created() {
    this.getListAkun();
    this.getListPenerima();
  },
};
</script>
<style scoped>
.centered-input >>> input {
  text-align: right;
}
</style>