<template>
    <v-app>
        <Header/>
        <v-main>
            <v-container fluid>
                <router-view/>
            </v-container>
        </v-main>
        <Footer/>
    </v-app>
</template>

<script>
import Header from './Header.vue'
import Footer from './Footer.vue'
export default{
    name:'Layout',
    components:{Header, Footer}
}
</script>
