let akuntan = [

    { title: "Transaksi", icon: "mdi-view-dashboard", link: "/jurnal" },
    { title: "Register Akun", icon: "mdi-image", link: "/akun" },
    { title: "Laporan Aktivitas", icon: "mdi-image", link: "/laporan/aktivitas" },
    { title: "Laporan Neraca", icon: "mdi-image", link: "/laporan/neraca" },
    { title: 'Buku Besar', icon: 'mdi-image', link: '/bukubesar' }

];

export default akuntan;