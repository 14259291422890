let akuntanAdmin = [
    { title: "Transaksi", icon: "mdi-book-edit", link: "/jurnal", group:false },
    { title: "Register Akun", icon: "mdi-book-cog-outline", link: "/akun", group:false },
    {title:'Buku Besar', icon:'mdi-book-clock', link:'/bukubesar', group:false},
    { title: "Laporan", group:true, 
      list:[
        {title:'Aktivitas', icon:'mdi-book-open-blank-variant', link:'/laporan/aktivitas'},
        {title:'Neraca', icon:'mdi-book-open-variant', link:'/laporan/neraca'},
      ]
    },
    {title: "Register User", icon: "mdi-card-account-details", link:'/user/register', group:false}
]

export default akuntanAdmin