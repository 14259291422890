<template>
  <v-row>
    <v-col sm="12" md="8" offset-md="2">
      <v-card>
        <v-card-title>
          <span class="text-5">Register User</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="12" md="8" offset-md="2" xsm="12">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  label="Username"
                  :rules="rules.username"
                  autocomplete="off"
                  v-model="form.username"
                ></v-text-field>
                <v-text-field
                  label="Nama Lengkap"
                  autocomplete="off"
                  :rules="rules.name"
                  v-model="form.nama"
                ></v-text-field>
                <v-text-field
                  label="Password"
                  autocomplete="off"
                  :rules="rules.password"
                  v-model="form.password"
                  :append-icon="iconStatus ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="iconStatus ? 'text' : 'password'"
                  @click:append="iconStatus = !iconStatus"
                ></v-text-field>
                <v-select
                  label="Status Akses"
                  :rules="rules.status_akses"
                  :items="items"
                  v-model="form.status"
                ></v-select>
                <v-checkbox label="Admin" v-model="form.admin"></v-checkbox>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" class="ma-2" @click="save">
            <v-icon>mdi-checkbox-marked-circle</v-icon>
            &nbsp; Simpan
          </v-btn>
          <v-btn color="red" class="ma-2" dark @click="batal">
            <v-icon>mdi-cancel</v-icon>
            &nbsp; Batal
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col xsm="12" sm="12" md="12">
      <v-data-table :headers="headers" :items="dataUser"

    :items-per-page="10"
    class="elevation-1">
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { user } from "../api";
export default {
  data() {
    return {
      valid: true,
      iconStatus: false,
      rules: {
        username: [
          (v) => !!v || "Tidak boleh bosong!!",
          (v) => /^\S*$/.test(v) || "Tidak boleh ada spasi!!",
          (v) => (v && v.length >= 3) || "Tidak boleh kurang dari 3 huruf.",
        ],
        name: [
          (v) => !!v || "Tidak boleh bosong!!",
          (v) => (v && v.length >= 3) || "Tidak boleh kurang dari 3 huruf.",
        ],
        password: [
          (v) => !!v || "Tidak boleh bosong!!",
          (v) => (v && v.length >= 8) || "Tidak boleh kurang dari 8!!!",
        ],
        status_akses: [(v) => !!v || "Tidak boleh bosong!!"],
      },
      form: {
        nama: null,
        username: null,
        password: null,
        status: null,
        admin: false,
      },
      items: [
        { text: "Semua", value: "all" },
        { text: "Akuntan", value: "akuntan" },
      ],
      headers:[
          {text: 'Username', value:'username'},
          {text:'Nama', value:'nama'},
          {text:'Status', value:'status'},
          {text:'Admin', value:'isAdmin'}

      ],
      dataUser: [],
    };
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
       this.createUser()
       this.batal()
      }
    },
    batal() {
      (this.form = {
        nama: null,
        username: null,
        password: null,
        status: null,
        admin: false,
      }),
        this.$refs.form.resetValidation();
    },
    async getUser() {
      try {
        let res = await user.getUserAll();
        if (res.status === 200) {
          this.dataUser = res.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async createUser() {
      try {
        let res = await user.createNewUser(this.form);
        if (res.status === 200) {
          this.getUser();
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  created() {
    this.getUser();
  },
};
</script>
