<template>
  <v-dialog v-model="isShow" max-width="290">
      <v-card>
          <v-card-title class="h5">Info</v-card-title>
          <v-card-text>
              {{pesan}}
          </v-card-text>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="ok">ASIAAAAP!!!</v-btn>
          </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
export default {
  name:"InfoDialog",
  props:{
      isShow: Boolean,
      pesan: String
  },
  data() {
    return {
        status:true
    };
  },
  methods:{
      ok(){
          this.$emit('updateStatus', false)
      }
  }
};
</script>