<template>
    <v-app>
        <v-row justify="center">
            <v-col sm="10" md="8" class="h-">
                <h3>Tampaknya anda tersesat....</h3>
            </v-col>
        </v-row>
    </v-app>
</template>
<script>
export default{
    name:'NotFound'
}
</script>
<style scoped>
.h-{
    margin-top:20px
}
</style>
