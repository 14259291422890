<template>
  <v-footer class="blue darken-4" dark app>
      <v-row>
        <v-col align="center" class="v-c">
          <b>Make with 	&#10084;</b>
        </v-col>
        
      </v-row>
  </v-footer>
</template>
<script>
export default {
    name:'Footer'
};
</script>
<style scoped>
.v-c{
  margin: 5px;
}
</style>
