import { axiosApi } from "./index";

let laporan = {
    getAktivitas:(form)=>{
        return axiosApi.post('/labarugi', form)
    },
    getNeraca:(form)=>{
        return axiosApi.post('/neraca', form)
    }
}

export default laporan