<template>
  <v-row>
    <v-col sm="12" md="12">
      <v-card elevation="2" outlined>
        <v-card-title>Jurnal</v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Ref</th>
                  <th class="text-left">Tanggal</th>
                  <th class="text-left">Akun</th>
                  <th class="text-left">Nama Akun</th>
                  <th class="text-left">Keterangan</th>
                  <th class="text-right">Debit</th>
                  <th class="text-right">Kredit</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(j, index) in jurnal " :key="index">
                    <td>{{j.ref}}</td>
                    <td>{{tanggal(j.tgl)}}</td>
                    <td>{{j.akun}}</td>
                    <td>{{j.nama}}</td>
                    <td>{{j.keterangan}}</td>
                    <td class="text-right">
                        <div v-if="j.status==='D'">
                            {{rupiah(j.nominal)}}
                        </div>
                        <div v-else>
                           0
                        </div>
                    </td>
                    <td class="text-right">
                        <div v-if="j.status==='K'">
                            {{rupiah(j.nominal)}}
                        </div>
                        <div v-else>
                            0
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="5" class="text-right"><h3>Balance</h3></td>
                    <td class="text-right"><h3>{{rupiah(total.debit)}}</h3></td>
                    <td class="text-right"><h3>{{rupiah(total.kredit)}}</h3></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-spacer></v-spacer>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {jurnal, formatRupiah, dateIndo} from '../api'
export default {
  data() {
    return {
        jurnal: [],
        total:{debit:0, kredit:0}
    };
  },
  methods:{
      rupiah(value){
          return formatRupiah(value, '')
      },
      tanggal(value){
          return dateIndo(value)
      },
      async getJurnal(){
          try{
              let res = await jurnal.getJurnalList()
              if(res.status===200){
                  this.jurnal = res.data.jurnal
                  this.total = res.data.total
              }
          }catch(e){
              console.log(e)
          }
      }
  },
  created(){
      this.getJurnal()
  }
};
</script>
