import Vue from 'vue'
import VueRouter from 'vue-router'
import jurnal from './jurnal'
import Login from '../views/Login'
import NotFound  from '../views/NotFound'
import store from '../store'
import user from './user'

Vue.use(VueRouter)

const routes = [
  jurnal,
  user,
  {
    path:'/login',
    name:'Login',
    component:Login,
    meta:{
      guest:true
    }
  },
  {path:'*', component:NotFound, meta:{notFound:true}}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next)=>{
  document.title = `CAKEP | ${to.matched[to.matched.length-1].name}`
  if(to.matched.some(record=> record.meta.authAll)){
    if(store.getters.getUser!==null){
      next()
    }else{
      next('/login')
    }
  }else if(to.matched.some(record=>record.meta.notFound)){
    next()
  }else if(to.matched.some(record=>record.meta.authAdmin)){
    if(store.getters.getUser.admin){
      next()
    }else{
      next('/')
    }
  }else{
    if(store.getters.getUser!==null){
      next('/')
    }else{
      next()
    }
  }
})

export default router
