
<template>
  <div>
    <v-navigation-drawer v-model="drawer" class="blue lighten-1" dark app>
      <v-list-item>
        <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> CAKEP </v-list-item-title>
          <v-list-item-subtitle> Catatan Keuangan Pondok </v-list-item-subtitle>
        </v-list-item-content> </v-list-item
      >.
      <v-divider></v-divider>

      <v-list v-for="(item, index) in menus" :key="index">
        <v-list-item :to="item.link" link v-if="!item.group">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group v-else prepend-icon="mdi-chart-box" :value=valueDropdown active-class="white--text">
          <template v-slot:activator>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </template>
          <v-list-item v-for="(list, index_) in item.list" :key=index_  link :to=item.list[index_].link  active-class="white--text">
            <v-list-item-icon>
              <v-icon>{{ list.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ list.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar class="blue darken-4" dark app>
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        darken
      ></v-app-bar-nav-icon>
      <v-toolbar-title>CAKEP</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn plain class="ma-1" v-bind="attrs" v-on="on">
            <v-icon>mdi-account</v-icon>&nbsp; {{ $store.getters.getUser.name }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="statusUpdate = true">
            <v-list-item-icon>
              <v-icon>mdi-account-key</v-icon>
            </v-list-item-icon>
            <v-list-item-title> Ubah Password </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-account-arrow-right</v-icon>
            </v-list-item-icon>
            <v-list-item-title> Logout </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <UpdatePassword :show="statusUpdate" @updateStatus="closeUpdate($event)" />
  </div>
</template>

<script>
import { user } from "../api";
import akuntan from "./menu/akuntan";
import akuntanAdmin from "./menu/akuntanAdmin";
import UpdatePassword from "../views/UpdatePassword";
export default {
  name: "Header",
  components: { UpdatePassword },
  data() {
    return {
      menus: [],
      drawer: true,
      group: null,
      statusUpdate: false,
      valueDropdown:false
    };
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  methods: {
    logout() {
      this.postLogout();
    },
    closeUpdate(value) {
      this.statusUpdate = value;
    },
    async postLogout() {
      try {
        let res = await user.logoutUser();
        if (res.status === 200) {
          this.$store.dispatch("clearUser");
          this.$router.push("/login");
        }
      } catch (e) {
        console.log(e.response);
      }
    },
  },
  created() {
    if (this.$store.getters.getUser.admin) {
      this.menus = akuntanAdmin;
    } else {
      this.menus = akuntan;
    }
  },
};
</script>
<style scoped>
</style>
