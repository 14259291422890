<template>
  <v-row>
    <v-col sm="12" md="12">
      <v-card>
        <v-card-title>
          <span class="text-5">{{ akun.nama }}</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="12" md="3">
              <v-select
                label="Dari Bulan"
                :items="items_"
                v-model="form.start"
              ></v-select>
            </v-col>
            <v-col sm="12" md="3">
              <v-select
                label="Sampai Bulan"
                :items="items_"
                v-model="form.end"
              ></v-select>
            </v-col>
            <v-col sm="12" md="1">
              <v-btn color="primary" :disabled="status_button" @click="load"
                ><v-icon>mdi-book-search</v-icon> &nbsp;Proses</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="12" v-if="data">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <th class="text-left">No.</th>
                    <th class="text-left">Tgl</th>
                    <th class="text-left">Keterangan</th>
                    <th class="text-right">Debit</th>
                    <th class="text-right">Kredit</th>
                    <th class="text-right">Saldo</th>
                  </thead>
                  <tbody>
                    <tr v-for="(d, index) in data.akun" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>{{ date_(d.tgl) }}</td>
                      <td>{{ d.ket }}</td>
                      <td class="text-right">
                        <div v-if="d.status === 'D'">
                          <b>{{ rupiah(d.nominal) }}</b>
                        </div>
                        <div v-else>
                          <b>-</b>
                        </div>
                      </td>
                      <td class="text-right">
                        <div v-if="d.status === 'K'">
                          <b>{{ rupiah(d.nominal) }}</b>
                        </div>
                        <div v-else>
                          <b>-</b>
                        </div>
                      </td>
                      <td class="text-right">
                        <b>{{ calculateSaldo(index) }}</b>
                        <!--v-btn class="mx-2" fab dark small color="success" @click="showUuid(d.uuid)">
                                                   <v-icon>mdi-eye</v-icon>
                                                </v-btn-->
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3" class="text-right"><b>Saldo</b></td>
                      <td colspan="2" class="text-right">
                        <b>{{ rupiah(data.saldo) }}</b>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { akun, bukuBesar, formatRupiah, dateIndo, month } from "../api";
export default {
  data() {
    return {
      akun: { akun: null, nama: null },
      status_button: false,
      total_test: [],
      data: null,
      form: {
        akun: null,
        start: null,
        end: null,
      },
      items_: month,
    };
  },

  methods: {
    rupiah(value) {
      if (value < 0) {
        return `(${formatRupiah(value, "")})`;
      } else {
        return formatRupiah(value, "");
      }
    },
    date_(value) {
      return dateIndo(value);
    },
    calculateSaldo(index) {
      let saldo = 0;
      for (let i = 0; i < this.data.akun.length; i++) {
        if (
          parseInt(this.data.kelompok) === 1 ||
          parseInt(this.data.kelompok) === 5
        ) {
          if (this.data.akun[i].status === "D") {
            saldo = saldo + this.data.akun[i].nominal;
          } else if (this.data.akun[i].status === "K") {
            saldo = saldo - this.data.akun[i].nominal;
          }
        } else if (
          parseInt(this.data.kelompok) === 2 ||
          parseInt(this.data.kelompok) === 3 ||
          parseInt(this.data.kelompok) === 4
        ) {
          if (this.data.akun[i].status === "K") {
            saldo = saldo + this.data.akun[i].nominal;
          } else if (this.data.akun[i].status === "D") {
            saldo = saldo - this.data.akun[i].nominal;
          }
        }

        if (i == index) {
          break;
        }
      }
      if (saldo < 0) {
        return `(${formatRupiah(saldo, "")})`;
      } else {
        return formatRupiah(saldo, "");
      }
    },
    showUuid(value) {
      console.log(value);
    },
    load() {
      this.getData();
    },
    async getData() {
      try {
        this.status_button = true;
        let res = await bukuBesar.bukuBesarAkunAll(this.form);
        if (res.status === 200) {
          this.data = res.data;
          this.status_button = false;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getAkun(uuid) {
      try {
        let res = await akun.getAkunUuid(uuid);
        if (res.status === 200) {
          this.akun = res.data;
          this.form.akun = this.akun.akun;
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  created() {
    this.getAkun(this.$route.params.uuid);
  },
};
</script>
