import Layout from '../container/Layout'
import RegisterUser from '../views/RegisterUser'

const user = {
    
        component:Layout,
        path:'/user/register',
        children:[
            {
                path:'/user/register',
                name:'Register User',
                component:RegisterUser,
                meta:{
                    authAdmin:true
                }
            }

        ]
    }


export default user;