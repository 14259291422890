import {axiosApi} from './index'

let jurnal = {
    saveJurnal: (form)=>{
        return axiosApi.post(`/jurnal`, form)
    },
    getPenerima:()=>{
        return axiosApi.get(`/transaksi/penerima`)
    },
    savePenerima:(form)=>{
        return axiosApi.post(`/transaksi/penerima`, form)
    },
    getJurnalList:()=>{
        return axiosApi.get('/jurnal/list')
    }
}

export default jurnal