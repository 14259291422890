<template>
  <v-row>
    <v-col sm="12" md="4" class="-t">
      <v-row>
        <v-col sm="12" md="12">
          <v-text-field
            label="Akun"
            placeholder="No akun atau nama akun"
            outlined
            autocomplete="off"
            v-model="search_key"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col sm="12" md="12">
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-left">No.</th>
            <th class="text-left">No. Akun</th>
            <th class="text-left">Nama Akun</th>
            <th class="text-left">#</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(b, index) in result_data" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ b.akun }}</td>
            <td>{{ b.nama }}</td>
            <td>
              <v-btn class="mx-2" fab dark small color="primary" @click="detail(b.uuid)"><v-icon dark>mdi-eye</v-icon></v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import { bukuBesar, month } from "../api";
export default {
  name: "BukuBesar",
  data() {
    return {
      result_data: [],
      search_key: null,
      list_bulan:[],
      start:null,
      end:null
    };
  },
  methods: {
    detail(value){
      this.$router.push(`/bukubesar/detail/${value}`)
    },
    async getList() {
      try {
        let res = await bukuBesar.getBukuBesarAll();
        if (res.status === 200) {
          this.result_data = res.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getBySearch() {
      try {
        let res = await bukuBesar.getBukuBesarSearch(this.search_key);
        if (res.status === 200) {
          this.result_data = res.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  watch: {
    search_key: function (e) {
      if (e.length >= 3) {
        this.getBySearch()
      }else if(e.length===0){
          this.getList()
      }
    },
  },
  created() {
    this.getList();
    this.list_bulan = month
  },
};
</script>
<style scoped>
.-t {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
