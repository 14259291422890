<template>
  <v-app class="bg-">
    <v-row justify="center">
      <v-col sm="12" md="4" class="h-">
        <v-card elevation="2" outlined :loading="loading">
          <v-card-title primary-title class="justify-center"
            ><b>CAKEP</b></v-card-title
          >
          <v-card-text>
            <v-row class="-r">
              <v-col sm="10" offset-sm="1" md="10" offset-md="1">
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-text-field
                    label="Username"
                    v-model="form.username"
                    :rules="rules.username"
                  ></v-text-field>
                  <v-text-field
                  v-on:keyup.enter="login"
                    label="Password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    @click:append="show = !show"
                    :rules="rules.password"
                    v-model="form.password"
                  ></v-text-field>
                  <v-btn
                    color="success"
                    style="width: 100%; margin-top: 5px"
                    :disabled="loading"
                    @click="login"
                    >Login <v-icon dark right> mdi-login </v-icon></v-btn
                  >
                </v-form>
                <SnackBar
                  :status="showSnack"
                  :text="text"
                  @closeSnackBar="close($event)"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>
<script>
import SnackBar from "./SnackBar";
import { user } from "../api";
export default {
  name: "Login",
  components: {
    SnackBar,
  },
  data() {
    return {
      loading:false,
      valid: true,
      show: false,
      text: null,
      showSnack: false,
      form: {
        username: null,
        password: null,
      },
      rules: {
        username: [(v) => !!v || "Username tidak boleh kosong"],
        password: [(v) => !!v || "Password tidak boleh kosong"],
      },
    };
  },
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.getToken();
      }
    },
    async getToken() {
      this.loading=true
      try {
        let res = await user.login(this.form);
        if (res.status == 200) {
         this.$store.dispatch('setUser', res.data)
         this.$router.push('/')
        }
      } catch (e) {
        if (e.response.status === 403) {
          if (this.showSnack) this.showSnack = false;
          this.text = "Username atau Password salah!!!";
          this.showSnack = true;
          this.loading=false
        }
      }
    },
    close(value) {
      this.showSnack = value;
    },
  },
};
</script>
<style scoped>
.h- {
  margin-top: 150px;
}

.bg- {
  background-color: #eceff1;
}

.-r {
  margin-bottom: 20px;
}
</style>

