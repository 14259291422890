import {axiosApi} from './index'

const akun = {
    getKelompok: ()=>{
        return axiosApi.get(`/kelompok`)
    },
    createKelompok:(form)=>{
        return axiosApi.post(`/kelompok`, form)
    },
    getKelompokList: ()=>{
        return axiosApi.get(`/kelompok/list`)
    },
    saveJenis: (form)=>{
        return axiosApi.post(`/jenis`, form)
    },
    getJenis:()=>{
        return axiosApi.get(`/jenis`)
    },
    getJenisList: ()=>{
        return axiosApi.get(`/jenis/list`)
    },
    getAkun: ()=>{
        return axiosApi.get(`/akun`)
    },
    saveAkun: (form)=>{
        return axiosApi.post(`/akun`, form)
    },
    getAkunList:()=>{
        return axiosApi.get(`/akun/list`)
    },
    getAkunUuid:(uuid)=>{
        return axiosApi.get(`/akun/${uuid}`)
    }
}

export default akun