import { axiosApi } from "./index";

let user ={
    login: (form)=>{
        return axiosApi.post('/user/login', form)
    },
    logoutUser:()=>{
        return axiosApi.get('/user/logout')
    },
    updatePassword: (form)=>{
        return axiosApi.put('/user/update', form)
    },
    getUserAll:()=>{
        return axiosApi.get('/user/register')
    },
    createNewUser:(form)=>{
        return axiosApi.post('/user/register', form)
    }

}

export default user;