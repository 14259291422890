import Layout from '../container/Layout'
import AkunGenerate from '../views/AkunGenerate'
import JurnalList from '../views/JurnalList'
import BukuBesar from '../views/BukuBesar'
import DetailBukuBesar from '../views/DetailBukuBesar'
import LaporanAktivitas from '../views/LaporanAktivitas'
import LaporanNeraca from '../views/LaporanNeraca'

const jurnal =
    {
        path:'/',
        component: Layout,
        redirect:'/jurnal',
        children:[
            {
                path:'/jurnal',
                name:'Jurnal',
                component:JurnalList,
                meta:{
                    authAll:true
                }

            },
            {
                path:'/laporan/aktivitas',
                name:'Laporan Aktivitas',
                component:LaporanAktivitas,
                meta:{
                    authAll:true
                }

            },
            {
                path:'/laporan/neraca',
                name:'Laporan Neraca',
                component:LaporanNeraca,
                meta:{
                    authAll:true
                }

            },
            {
                path:'/akun',
                name:'Akun',
                component:AkunGenerate,
                meta:{
                    authAll:true
                }
            },
            {
                path:'/bukubesar',
                name:'Buku Besar',
                component:BukuBesar,
                meta:{
                    authAll:true
                }
            },
            {
                path:'/bukubesar/detail/:uuid',
                name:'Detail Buku Besar',
                component:DetailBukuBesar,
                meta:{
                    authAll:true
                }
            }
        ]
    }


export default jurnal