<template>
  <v-row>
    <v-col md="12" sm="12">
      <v-tabs>
        <v-tab>Kelompok</v-tab>
        <v-tab @click="subKelompok">Sub Kelompok</v-tab>
        <v-tab @click="akun">Akun</v-tab>
        <v-tab-item class="tab-space">
          <Kelompok/>
        </v-tab-item>
        <v-tab-item class="tab-space">
          <SubKelompok ref="reloadData1"/>
        </v-tab-item>

        <v-tab-item class="tab-space">
          <Akun ref="reloadData2"/>
        </v-tab-item>
      </v-tabs>
    </v-col>
  </v-row>
</template>
<script>
import Kelompok from './Kelompok';
import SubKelompok from './SubKelompok'
import Akun from './Akun'
export default {
  name: "GenerateAkun",
  components: { Kelompok, SubKelompok, Akun },
  data() {
    return {
      status_sub:false,
      status_akun:false
    };
  },
  methods:{
    subKelompok(){
      if(this.status_sub){
        this.$refs.reloadData1.getData()
      }
      if(this.status_sub===false){
        this.status_sub=true
      }
      
    },
    akun(){
      if(this.status_akun){
        this.$refs.reloadData2.getData()
      }
      if(this.status_akun===false){
        this.status_akun = true
      }
    }
  }
};
</script>

<style scoped>
.tab-space {
  margin-top: 10px;
}
</style>